<template>
  <div>
    <b-row>
      <b-col cols="10" class="login-card text-center p-3 shadow rounded">
        <div>
          <img
            class="my-5 py-3 login-logo"
            :src="logo ? logo : require('@/assets/images/d-dot-logo.svg')"
            alt
          />
          <div class="form-group">
            <input
              ref="usernameInput"
              v-model="username"
              type="text"
              class="form-control rounded-pill"
              placeholder="Username"
            />
          </div>
          <div class="form-group">
            <input
              v-model="password"
              type="password"
              class="form-control rounded-pill"
              placeholder="Password"
              @keyup.enter="login()"
            />
          </div>
          <button
            @click="login()"
            class="btn w-100 rounded-pill bg-color-primary text-white"
            :disabled="isDisable"
          >
            Login
          </button>
          <div class="text-danger pt-2">{{ message }}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fetchIconsFromAPI } from "@/utils/fetchIconsFromAPI.js";
import { changeFavicon, changeMetaTag } from "@/utils/changeFavicon.js";

export default {
  data() {
    return {
      username: "",
      password: "",
      message: "",
      logo: "",
      fixBrandID: this.$fixBrandId,
      brandId: null,
      isDisable: false,
      favicon: ""
    };
  },
  created() {
    this.$store.dispatch("setBrand", null);
  },
  async mounted() {
    this.$refs.usernameInput.focus();

    var nodeList = document.getElementsByTagName("link");
    if (nodeList.length) {
      for (const e of nodeList) {
        if (
          e.getAttribute("href") == "/img/icons/favicon-16x16.png" ||
          e.getAttribute("href") == "/img/icons/favicon-32x32.png"
        ) {
          document.getElementsByTagName("head")[0].removeChild(e);
        }
      }
    }

    await this.getLogo();

    changeFavicon(this.favicon);
  },
  methods: {
    async setManifast() {
      var myDynamicManifest = {
        icons: [
          {
            src: this.logo,
            sizes: "192x192",
            type: "image/png"
          },
          {
            src: this.logo,
            sizes: "512x512",
            type: "image/png"
          },
          {
            src: this.logo,
            sizes: "192x192",
            type: "image/png",
            purpose: "maskable"
          },
          {
            src: this.logo,
            sizes: "512x512",
            type: "image/png",
            purpose: "maskable"
          }
        ]
      };
      const link = document.createElement("link");
      link.rel = "manifest";
      const stringManifest = JSON.stringify(myDynamicManifest);
      link.setAttribute(
        "href",
        "data:application/json;charset=utf-8," +
          encodeURIComponent(stringManifest)
      );
      document.head.appendChild(link);
    },
    async login() {
      this.message = "";
      if (this.validate()) {
        this.isDisable = true;
        // await this.$axios
        //   .post(this.$baseUrl + "/api/Authenticate", {
        //     username: this.username,
        //     password: this.password
        //   })
        //   .then(async response => {
        //     if (response.data.result === 1) {
        await this.$axios
          .post(this.$chatBaseUrl + "/user/update-token", {
            email: this.username,
            password: this.password
            // token: response.data.detail.token
          })
          .then(async response2 => {
            if (response2.data.result === 1) {
              var timestamp = new Date().getTime();
              this.$cookies.set(
                "d-order-session-token",
                response2.data.detail.token
              );
              this.$headersChat.Authorization = response2.data.detail.token
                ? `Bearer ${response2.data.detail.token}`
                : "null";
              this.$cookies.set("d-order-username", this.username);
              this.$cookies.set("d-order-timestamp", timestamp);
              this.$store.dispatch("setTimestamp", timestamp);
              await this.getAllBrand();
              await this.$store.dispatch("getChatAdminProfile");
              await this.$store.dispatch(
                "updateChatAdminProfileIsAvailable",
                true
              );
              this.isDisable = false;
            } else {
              this.isDisable = false;
              this.message = response2.data.message;
            }
          });
        //   } else {
        //     this.isDisable = false;
        //     this.message = response.data.message;
        //   }
        // });
      } else {
        this.message = "กรุณากรอกข้อมูล";
      }
    },
    async getLogo() {
      const response = await fetchIconsFromAPI();

      if (response && response.detail) {
        const { detail } = response;
        this.logo = detail.logo;
        this.favicon = detail.favicon;
        changeMetaTag("og:title", detail.brandCode);
        changeMetaTag("og:description", detail.brandCode);
        changeMetaTag("og:image", this.favicon);
      }
    },

    async getAllBrand() {
      await this.$axios
        .get(this.$baseUrl + "/api/admin/brand")
        .then(response => {
          if (response.data.result === 1) {
            this.$store.dispatch("setBrand", response.data.detail[0].id);
            this.$router.push({
              path: "/chat",
              query: {
                brandID: response.data.detail[0].id,
                isMobile: this.$route.query.isMobile == "true" ? "true" : ""
              }
            });
          }
        });
    },
    validate() {
      if (this.username === "") return false;
      if (this.password === "") return false;
      return true;
    }
  }
};
</script>

<style>
.login-card {
  width: 360px;
  height: 380px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.login-logo {
  height: 100%;
  max-height: 100px;
  object-fit: scale-down;
  object-position: center center;
  width: 80%;
}
</style>
